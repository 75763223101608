import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// //this was in order to get my token
// if (process.env.NODE_ENV === 'development') {
//   // Set the debug token in development mode
//   global.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app,{
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY), 
  isTokenAutoRefreshEnabled: true
});

export default appCheck;
