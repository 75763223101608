import React from 'react';
import { Link } from 'react-router-dom';
import imgBot from '../images/404.gif'


const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img
        src={imgBot}
        alt="Page not found"
        className="w-1/2 md:w-1/3 mt-6"
      />
      <Link
        to="/"
        className="text-lg text-blue-900 hover:text-gray-900 mt-4 font-bold"
      >
        Go back to home
      </Link>
    </div>
  );
};

export default NotFound;
