import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const NavLinks = () => {
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('selectedLanguage', lng);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/about">
        {t('navbar.about')}
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/services">
        {t('navbar.services')}
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/portefolio">
        {t('navbar.portfolio')}
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact">
        {t('navbar.contact')}
      </HashLink>

        <div className="relative inline-block text-left" onBlur={handleDropdownClose}>
            <button
            type="button"
            className="text-gray-500 bg-white hover:bg-white focus:ring-4 focus:outline-none focus:ring-blue-900 font-extrabold rounded-lg px-4 py-2.5 text-center inline-flex items-center dark:focus:ring-blue-800"
            onClick={handleDropdownToggle}
            >
            {t('navbar.language')}
            <svg
                className="w-2.5 h-2.5 ml-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
            >
                <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
                />
            </svg>
            </button>
            {isDropdownOpen && (
            <div className="origin-top-right absolute right-0 mt-2 w-44 rounded-lg shadow-lg bg-white">
                <div className="py-2">
                <button
                    className="block py-2 hover:bg-gray-600 hover:text-white dark:hover:bg-blue-900 dark:hover:text-white w-full"
                    onMouseDown={() => changeLanguage('en')}
                >
                    English
                </button>
                <button
                    className="block py-2 hover:bg-gray-600 hover:text-white dark:hover:bg-blue-900 dark:hover:text-white w-full"
                    onMouseDown={() => changeLanguage('fr')}
                >
                    French
                </button>
            </div>
        </div>
        )}
      </div>
    </>
  );
};

export default NavLinks;