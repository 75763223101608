import React from 'react';
import { useTranslation } from 'react-i18next';

const History = () => {

  const { t } = useTranslation();

  return (
    <section className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 md:text-3xl text-blue-900">{t('history.title')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl text-gray-600 font-semibold mb-4">{t('history.establishedTitle')}</h3>
            <p className="text-gray-600">{t('history.establishedText')}</p>
          </div>
          <div>
            <h3 className="text-xl text-gray-600 font-semibold mb-4">{t('history.valuesTitle')}</h3>
            <p className="text-gray-600">{t('history.valuesText')}</p>
            <ul className="list-disc list-inside text-gray-600">
              <li>{t('history.valuesCollaboration')}</li>
              <li>{t('history.valuesSharing')}</li>
              <li>{t('history.valuesInnovation')}</li>
              <li>{t('history.valuesResponsibility')}</li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl text-gray-600 font-semibold mb-4">{t('history.visionTitle')}</h3>
            <p className="text-gray-600">{t('history.visionText')}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default History;
