import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Clients from '../components/Clients';
import Products from '../components/Products';
import {useDocTitle} from '../components/CustomHook';
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import { Helmet } from 'react-helmet';


const Portefolio = () => {

    useDocTitle('kesnelDigital - Portefolio');

    return (
        <I18nextProvider i18n={i18n}>
            <div>
                <Helmet>
                <meta name="description" content="Explore Kesnel Digital's portfolio and discover our diverse range of innovative solutions that showcase our expertise in creating digital experiences that exceed expectations. Get inspired by our success stories and the value we bring to each project." />
                </Helmet>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200">
                <Products />
            </div>
            <Clients />

            <Footer />
        </I18nextProvider>
    );
}

export default Portefolio;