import React from 'react';
import { Link } from 'react-router-dom';
import ServicesComponent from '../components/Services';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';
import { useDocTitle } from '../components/CustomHook';
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


const Services = () => {
  useDocTitle('kesnelDigital - Services');
  const { t } = useTranslation();
  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <Helmet>
          <meta name="description" content="Discover a wide range of services offered by Kesnel Digital. We specialize in web development, mobile app development, innovative software solutions, and IT consulting. Elevate your business with our expertise and creative solutions." />
        </Helmet>
        <NavBar />
      </div>
      <div className="flex flex-col justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200">
        <ServicesComponent />
        <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
          {t('services.contact')}
        </Link>
      </div>
      <Footer />
    </I18nextProvider>
  );
}

export default Services;
