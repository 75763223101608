import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import frTranslation from "./locales/fr.json";

const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en'; // Default to 'en' if no language is selected

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation }
  },
  lng: selectedLanguage, // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false 
  }
});

export default i18n;
