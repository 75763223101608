import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import Footer from "../components/Footer";
import HeroAbout from "../components/HeroAbout";
import Intro from "../components/Intro";
import History from "../components/History";
import NavBar from "../components/Navbar/NavBar";
import { useDocTitle } from "../components/CustomHook";
import { Helmet } from 'react-helmet';

const About = () => {
  useDocTitle("kesnelDigital - About");

  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <Helmet>
          <meta name="description" content="Learn more about Kesnel Digital, discover our passion for innovation, teamwork, and delivering cutting-edge solutions to businesses worldwide." />
        </Helmet>
        <NavBar />
      </div>
      <div>
        <HeroAbout />
        <Intro />
        <History />
      </div>
      <Footer />
    </I18nextProvider>
  );
};

export default About;
