import React from 'react';
import { useTranslation } from 'react-i18next';
import immobeau from '../images/clients/immobeau.png';
// import kws from '../images/clients/kws.png';
// import geps from '../images/clients/geps.png';

const clientImage = {
  height: '10rem',
  width: 'auto',
  mixBlendMode: 'colorBurn'
};

const Clients = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{t('clients.title')}</h2>
          <div className='flex justify-center'>
            <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">{t('clients.subtitle')}</h2>
        </div>

        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <div className="grid sm:grid-cols-3 lg:grid-cols-3">
            {/* <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
              <img src={kws} alt="client" />
            </div> */}

            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
              <img src={immobeau} alt="client" />
            </div>

            {/* <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
              <img src={geps} alt="client" />
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;