import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import ServicesComponent from '../components/Services';
import {useDocTitle} from '../components/CustomHook';
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import { Helmet } from 'react-helmet';


const Home = () => {

    useDocTitle('kesnelDigital - Home');

    return (
        <I18nextProvider i18n={i18n}>
            <Helmet>
                <meta name="description" content="Welcome to Kesnel Digital, your gateway to innovative web development, mobile app solutions, and expert IT consulting services. Discover how we create, develop, and build digital solutions that drive growth and success for businesses worldwide." />
            </Helmet>
            <Hero />
            <Intro />
            <ServicesComponent />
            <Clients />
            <Cta/>
            <Footer />
        </I18nextProvider>
    )
}

export default Home;

